import EntityService from "./entity";

class ArticleCategoryService extends EntityService {
    constructor(name) {
        super(name);
    }

    /**
 * 获取Entity分页
 * @param {*} entities
 * @param {*} pageable
 * @returns
 */
    async getEntityPage(entities, pageable) {
        const result = await window.axios({
            url: `/article-categories`,
            method: "get",
            params: {
                ...entities,
                ...pageable,
            },
        });

        return result;
    }
}

export default new ArticleCategoryService("article-category");
