<template>
  <div class="pane-wrapper">
    <div class="table-wrapper">
      <y-table
        :columns="columns"
        :loading="loading"
        :service="service"
        :entity="entity"
        :filters="privateFilters"
        :sorter="sorter"
        @add="handleClickAdd"
      >
        <template slot="action" slot-scope="action">
          <a-button type="link" @click="handleEditClick(action.record)"
            >编辑</a-button
          >
          <a-popconfirm
            title="是否确认删除？"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleDeleteClick(action.record)"
          >
            <a-button type="link">删除</a-button>
          </a-popconfirm>
        </template>
      </y-table>
    </div>
    <editor
      :visible.sync="visible"
      :target="target"
      @close="handleEditorClose"
    ></editor>
  </div>
</template>

<script>
import Enums from "../../../enums";

import ArticleService from "@/services/article";

import Editor from "../editor";
const defaultFilters = {
  type: "SUPPLY",
};
const defaultEntity = {
  properties: {},
};
export default {
  components: { Editor },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
    },
    sorter: {
      type: Array,
    },
    entity: {
      type: Object,
      default: () => {
        return JSON.parse(JSON.stringify(defaultEntity));
      },
    },
  },
  data() {
    return {
      service: ArticleService,
      visible: false,
      visibleSortModal: false,
      privateFilters: JSON.parse(JSON.stringify(defaultFilters)),
      target: {},
      columns: [
        {
          dataIndex: "title",
          title: "新闻名称",
          sorter: true,
        },
        // {
        //   dataIndex: "category.name",
        //   title: "新闻分类",
        //   customRender: function (text) {
        //     return <y-tag value={text}></y-tag>;
        //   },
        // },
        {
          dataIndex: "type",
          title: "新闻内容类别",
          customRender: function (text) {
            return <y-tag type={"ArticleType"} value={text}></y-tag>;
          },
          filterMultiple: false,
          filters: Enums.getFiltersByName("ArticleCategory"),
        },
        {
          dataIndex: "properties.cover",
          title: "封面",
          scopedSlots: { customRender: "picture" },
        },
        // {
        //   dataIndex: "properties.picture",
        //   title: "新闻配图",
        //   scopedSlots: { customRender: "picture" },
        // },
        {
          dataIndex: "link",
          title: "新闻链接",
          scopedSlots: { customRender: "link" },
        },
        {
          dataIndex: "sequence",
          title: "权重",
          scopedSlots: { customRender: "sequence" },
          sorter: true,
        },
        {
          dataIndex: "enabled",
          title: "是否上架",
          scopedSlots: { customRender: "enabled" },
        },
        {
          dataIndex: "creater",
          title: "创建人",
        },
        {
          dataIndex: "createTime",
          title: "创建时间",
          scopedSlots: { customRender: "dateTime" },
        },
        {
          dataIndex: "updater",
          title: "更新人",
        },
        {
          dataIndex: "updateTime",
          title: "更新时间",
          scopedSlots: { customRender: "dateTime" },
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  watch: {
    filters: {
      handler: async function (val) {
        this.privateFilters = val;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleClickAdd() {
      this.target = {};
      this.visible = true;
    },
    async handleEditClick(record) {
      console.log(record)
      // this.target = record;
      this.target = await this.service.getEntity(record)
      console.log('aaa')
      console.log(this.target)
      this.visible = true;
    },
    handleEditorClose() {
      this.target = JSON.parse(JSON.stringify(defaultEntity));
      this.privateFilters = JSON.parse(JSON.stringify( this.filters));
    },
    async handleDeleteClick(record) {
      try {
        this.loading = true;
        await this.service.deleteEntity(record);
      } catch (error) {
        this.$message.error(
          error.response ? error.response.data.message : error.message
        );
      } finally {
        this.privateFilters = JSON.parse(JSON.stringify( this.filters));
        this.loading = false;
      }
    },
  },
};
</script>

<style>
</style>