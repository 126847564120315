<template>
  <a-drawer
      :visible="visible"
      width="50%"
      :maskClosable="false"
      @close="handleClose"
  >
    <a-form-model :model="entity" @submit.prevent="handleSave">
      <a-form-model-item
          label="文章分类"
          prop="category"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
      >
        <!--        <y-select-->
        <!--            v-model="entity.category"-->
        <!--            placeholder="请选择文章分类"-->
        <!--            :whole="false"-->
        <!--            :dataSource="articleCategories"-->
        <!--        ></y-select>-->
        <y-tree-select
            v-model="entity.category"
            placeholder="请选择文章分类"
            :whole="false"
            :dataSource="articleCategories"
        ></y-tree-select>
      </a-form-model-item>
      <a-form-model-item
          label="标题"
          prop="title"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
      >
        <a-input v-model="entity.title" allowClear></a-input>
      </a-form-model-item>
      <a-form-model-item
          label="描述"
          prop="description"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
      >
        <a-textarea
            v-model="entity.description"
            :autoSize="{ minRows: 2, maxRows: 6 }"
            allowClear
        />
      </a-form-model-item>
      <a-form-model-item
          label="发布时间"
          prop="publishTime"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
      >
        <y-date-picker
            v-model="entity.publishTime"
            :showTime="true"
        ></y-date-picker>
      </a-form-model-item>
      <a-form-model-item
          label="封面图"
          prop="cover"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
      >
        <y-upload
            v-model="entity.cover"
            :maximumQuantity="1"
            :minimumQuantity="1"
            type="picture"
            accept="image/*"
        ></y-upload>
      </a-form-model-item>
      <!-- <a-form-model-item
        label="配图"
        prop="properties.picture"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <y-upload
          v-model="entity.properties.picture"
          :maximumQuantity="1"
          :minimumQuantity="1"
          type="picture"
          accept="image/*"
        ></y-upload>
      </a-form-model-item> -->
      <a-form-model-item
          prop="type"
          label="类型"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
      >
        <y-radio v-model="entity.type" :type="'ArticleType'"></y-radio>
      </a-form-model-item>

      <a-form-model-item
          v-if="entity.type == enums.ArticleType.LINK.key"
          label="通告链接"
          prop="link"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
      >
        <a-input v-model="entity.link" allowClear></a-input>
      </a-form-model-item>
      <a-form-model-item
          v-if="entity.type == enums.ArticleType.VIDEO.key"
          label="通告视频"
          prop="link"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
      >
        <y-upload
            v-model="entity.link"
            :maximumQuantity="1"
            :minimumQuantity="1"
            accept="video/*"
        ></y-upload>
      </a-form-model-item>
      <a-form-model-item
          v-if="entity.type == enums.ArticleType.DOCUMENT.key"
          label="通告内容类型"
          prop="document.format"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
      >
        <y-radio
            v-model="entity.document.format"
            :type="'DocumentFormat'"
        ></y-radio>
      </a-form-model-item>
      <a-form-model-item
          v-if="entity.type == enums.ArticleType.DOCUMENT.key"
          label="通告内容"
          prop="document.content"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          style="height: 430px !important"
      >
        <y-rich-text-editor
            v-model="entity.document.content"
        ></y-rich-text-editor>
      </a-form-model-item>
      <a-form-model-item
          v-if="
          entity.type == enums.ArticleType.DOCUMENT.key &&
          entity.document.format == enums.DocumentFormat.PDF.key
        "
          label="通告文件"
          prop="document.url"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
      >
        <y-upload
            v-model="entity.document.url"
            :maximumQuantity="1"
            :minimumQuantity="1"
            accept="application/pdf"
        ></y-upload>
      </a-form-model-item>
      <a-form-model-item
          v-if="
          entity.type == enums.ArticleType.DOCUMENT.key &&
          entity.document.format == enums.DocumentFormat.MARKDOWN.key
        "
          label="通告文件"
          prop="document.url"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
      >
        <y-upload
            v-model="entity.document.url"
            :maximumQuantity="1"
            :minimumQuantity="1"
            accept="application/md"
        ></y-upload>
      </a-form-model-item>
            <a-form-model-item
          v-if="
          entity.type == enums.ArticleType.DOCUMENT.key &&
          entity.document.format == enums.DocumentFormat.WORD.key
        "
          label="通告文件"
          prop="document.url"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
      >
        <y-upload
            v-model="entity.document.url"
            :maximumQuantity="1"
            :minimumQuantity="1"
            accept="application/doc"
        ></y-upload>
      </a-form-model-item>
      <a-form-model-item
          prop="language"
          label="语言"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
      >
        <y-radio v-model="entity.language" :type="'Language'"></y-radio>
      </a-form-model-item>
      <a-form-model-item
          label="排序"
          prop="sequence"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
      >
        <a-input-number
            v-model="entity.sequence"
            :defaultValue="1"
            :min="0"
        ></a-input-number>
      </a-form-model-item>
      <a-form-model-item
          label="状态"
          prop="enabled"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
      >
        <a-switch v-model="entity.enabled"/>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 8, offset: 4 }">
        <a-space>
          <a-button htmlType="submit" :loading="loading" type="primary">
            保存
          </a-button>
          <a-button :loading="loading" @click="handleClose"> 取消</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import Enums from "@/enums/index.js";
import ArticleService from "@/services/article";
import ArticleCategoryService from "@/services/article-category";

const defaultEntity = {
  properties: {},
  document: {},
};

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    target: {
      type: Object,
    },
  },
  data() {
    return {
      enums: Enums,
      service: ArticleService,
      entity: JSON.parse(JSON.stringify(defaultEntity)),
      articleCategories: [],
    };
  },
  watch: {
    target: {
      handler: function name(value) {
        this.entity = {
          ...defaultEntity,
          ...value,
        };

        if (!this.entity.properties) {
          this.entity.properties = {};
        }

        if (!this.entity.document) {
          this.entity.document = {};
        }
      },
      deep: true,
    },
  },
  methods: {
    handleClose() {
      this.entity = JSON.parse(JSON.stringify(defaultEntity));
      console.log(this.entity)

      this.$emit("close");

      this.$emit("update:visible", false);
      this.$emit("update:loading", false);
    },
    async handleSave() {
      try {
        this.$emit("update:loading", true);
        if (!this.entity.id) {
          await this.service.addEntity(this.entity);
        } else {
          await this.service.updateEntity(this.entity);
        }
      } catch (error) {
        this.$message.error(
            error.response ? error.response.data.message : error.message
        );
      } finally {
        this.entity = JSON.parse(JSON.stringify(this.entity));

        this.$emit("close");
        this.$emit("update:visible", false);
        this.$emit("update:loading", false);
      }
    },
    async getArticleCategories(articleCategory, sort) {
      const result = await ArticleCategoryService.getEntities(
          articleCategory,
          sort
      );
      this.articleCategories = result.toTree();
    },
  },
  async mounted() {
    await this.getArticleCategories({}, {});
  },
};
</script>

<style>
</style>
